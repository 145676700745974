export const chemicalInjectorAnnular = {
  content1: {
    title: 'Overview',
    body: [
      `The Specialized Oil Tools Centralizing Annular Chemical Injector is engineered to perform in tight clearance ESP applications with the added benefit of being able
      to inject chemicals directly above the intake of the pump during production. This product can be ran directly above the ESP or between producing zones in wells that have
      multiple ESP’s installed`,
    ],
  },
  content2: {
    title: 'Applications',
    body: [
      'Land Based and Offshore ESP Applications',
      'All well types including conventional, un-conventional, and high deviation wells',
      'Annular and Internal chemical Injection configurations available',
      'Single, multiple, or shrouded ESP applications',
      'Wells where corrosion, bacteria, and flow assurance are a concern',
      'Wells where ESP sizing and tight clearances prohibit running capillary line below the ESP',
    ],
  },
  content3: {
    title: 'Benefits',
    body: [
      'Engineered Specifically for tight Equipment clearances and deviated wells',
      'Reduced the risk of corrosion due to effectively distributing chemicals near the intake of the pump',
      'Mitigates risk of hangups and issues during installation',
      'Dual check valve design eliminates the “U Tube Effect”',
      'Centralizes the ESP or production system, protecting vital components such as:',
      ['Motor Lead Extension (MLE) & Power Cables', 'Capillary Lines', 'Motors & Pump'],
    ],
  },
  content4: {
    title: 'Features',
    body: [
      'Compact NACE compliant design for easy installation, handling, and reliable operation',
      'Dual check valve for increased reliability and surface safety',
      '3-10 GPM injection flow rates',
    ],
  },
  tableData: {
    columns: {
      Header: 'Centralizing Annular Chemical Injector',
      columns: [
        {
          Header: 'CACI Size (Series)',
          accessor: 'size',
        },
        {
          Header: '**CACI OD(in)',
          accessor: 'in',
        },
        {
          Header: 'Casing Size (in)',
          accessor: 'casing',
        },
        {
          Header: 'Casing Weight (lbs/ft)',
          accessor: 'weight',
        },
        {
          Header: 'Control Line Size (in)',
          accessor: 'line',
        },
        {
          Header: 'Metallurgy',
          accessor: 'metallurgy',
        },
      ],
    },
    data: [
      {
        size: 500,
        in: 4.13,
        casing: 5,
        weight: '11.5-18',
        line: '1/4-3/8',
        metallurgy: '*Carbon Steel & Stainless Steel',
      },
      {
        size: 550,
        in: 4.5,
        casing: 5.5,
        weight: 23,
        line: '1/4-3/8',
        metallurgy: '*Carbon Steel & Stainless Steel',
      },
      {
        size: 550,
        in: 4.6,
        casing: 5.5,
        weight: '13-20',
        line: '1/4-3/8',
        metallurgy: '*Carbon Steel & Stainless Steel',
      },
      {
        size: 700,
        in: 5.5,
        casing: 7,
        weight: '32-38',
        line: '1/4-3/8',
        metallurgy: '*Carbon Steel & Stainless Steel',
      },
      {
        size: 700,
        in: 5.62,
        casing: 7,
        weight: '26-32',
        line: '1/4-3/8',
        metallurgy: '*Carbon Steel & Stainless Steel',
      },
      {
        size: 700,
        in: 5.75,
        casing: 7,
        weight: '17-26',
        line: '1/4-3/8',
        metallurgy: '*Carbon Steel & Stainless Steel',
      },
    ],
  },
}
