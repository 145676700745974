import React from 'react'

import { Card, CardList, HeroProduct } from '../components'
import { shieldMLE } from '../contentData'
import { imagePath } from '../utils'

export const ShieldMLEPage = () => {
  const { content1, content2, content3, content4 } = shieldMLE
  const gallery = ['MLEShield-1.jpg', 'MLEShield-2.jpg', 'MLEShield-3.jpg']
  return (
    <div>
      <HeroProduct
        title="MLE Shield™ - Premium Cable Protection"
        image={`url(${imagePath + 'MLEShield-1.jpg'})`}
        top
      />
      <Card title={content1.title} body={content1.body} left delay={100} />
      <CardList title={content2.title} body={content2.body} left delay={200} />
      <CardList title={content3.title} body={content3.body} left delay={300} />
      <CardList title={content4.title} body={content4.body} left delay={400} />
    </div>
  )
}
