import React from 'react'
import { faLinkedinIn, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faCommentDots } from '@fortawesome/free-solid-svg-icons'

import Fade from 'react-reveal/Fade'
import Jump from 'react-reveal/Jump'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SocialIcon } from '../components'

const date = new Date()
const currentYear = date.getFullYear()

const socialData = [
  {
    social: 'linkedin',
    link: 'https://www.linkedin.com/company/specialized-oil-tools',
    icon: faLinkedinIn,
  },
  {
    social: 'instagram',
    link: 'https://www.instagram.com/specialized_oil_tools/?hl=en',
    icon: faInstagram,
    delay: 100,
  },
  // { social: 'facebook', link: 'https://www.facebook.com', icon: faFacebookF, delay: 100 },
]

const socialIcons = data => (
  <div className="footer-social-icons row">
    {data.map((val, i) => {
      const { social, link, icon, delay } = val
      return (
        <div key={`foot-icon-${i}`}>
          <SocialIcon social={social} link={link} icon={icon} delay={delay} />{' '}
        </div>
      )
    })}
  </div>
)

export const Footer = () => (
  <Fade>
    <div>
      <footer className="footer">
        <div className="row footer-bottom">
          <div>{socialIcons(socialData)}</div>
          <div>
            <p>&copy; {currentYear} Specialized Oil Tools</p>
          </div>
        </div>
      </footer>
    </div>
  </Fade>
)
