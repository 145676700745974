import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Jump from 'react-reveal/Jump'

export const SocialIcon = ({ icon, social, link, delay = 0 }) => {
  return (
    <Jump delay={delay}>
      <div>
        <div className={`social-icon-container ${social}`}>
          <a href={link} target="_tab">
            <FontAwesomeIcon icon={icon} />
          </a>
        </div>
      </div>
    </Jump>
  )
}
