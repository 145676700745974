import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Card } from '../components'
import { about } from '../contentData'

class aboutPage extends Component {
  render() {
    const { windowMode } = this.props
    const { content1, content2, content3, content4 } = about
    return (
      <div className="content-wrapper" key={`about-page-${windowMode}`}>
        <div className="page-about">
          <div className="row">
            <Card title={content1.title} body={content1.body} top fix />
          </div>
          <div className="row">
            <Card title={content2.title} body={content2.body} left delay={100} />
            {windowMode === 'desktop' && (
              <Card title={content3.title} body={content3.body} right delay={100} />
            )}
          </div>
          {windowMode === 'mobile' && (
            <div className="row">
              <Card title={content3.title} body={content3.body} right delay={100} />
            </div>
          )}
          <div className="row">
            <Card title={content4.title} body={content4.body} delay={200} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({ windowMode: state.visuals.windowMode })

export const AboutPage = connect(mapStateToProps)(aboutPage)
