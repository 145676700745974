export const sandSafetyShield = {
  content1: {
    title: 'Overview',
    body: [
      `The Specialized Oil Tools Centralizers are designed to be ran in conjunction with tubing and pipe by threading directly onto the string to provide optimum centralization. They decrease installation and conveyance risks due to hangups in vertical, deviated, and horizontal wells.`,
    ],
  },
  content2: {
    title: 'Applications',
    body: [
      'Located directly above the ESP to ensure produced sand is captured and prevents any all back of sand entering the ESP during shut-ins',
      'Land Based and Offshore ESP Applications',
      `All well types including conventional, un-conventional, and high deviation wells`,
      `Single, multiple, or shrouded ESP applications`,
      `Designed to withstand the harshest down hole environments and erosion`,
      `Multiple sizes for various casing applications`,
    ],
  },
  content3: {
    title: 'Benefits & Advantages',
    body: [
      'Hardened and corrosion resistant materials ensure prolonged life and reliability',
      'ZERO moving parts for seamless and repeatable operation',
      'CFD optimized flow to reduce pressure drop and prevent sand packing during shut-ins',
      'Prevents the accumulation of solids in the ESP during shut-in and purges all captured sand at start up while maintaining fluid communication open',
      "Reduces load on ESP component's upon startup",
    ],
  },
  content4: {
    title: 'Features',
    body: [
      'Multiple sizes available for all ESP configurations',
      `ZERO moving parts ensures reliability and continuous performance without interruption`,
      'Seamlessly integrates into the production string for any OEM ESP',
      'Compatible with production chemical treatments and resists build up of scale',
      'Works in conjunction with Specialized Oil Tools Chemical Injection product offerings',
    ],
  },
}
