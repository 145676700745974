import { PRODUCT_CATEGORY } from '../utils'

const INITIAL_STATE = {
  productCategory: '',
}

export const products = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PRODUCT_CATEGORY:
      return {
        ...state,
        productCategory: action.payload,
      }
    default:
      return { ...state }
  }
}
