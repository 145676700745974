import React from 'react'

import { Card, CardList, HeroProduct, Table } from '../components'
import { centralizers } from '../contentData'
import { imagePath } from '../utils'

export const CentralizersPage = () => {
  const {
    content1,
    content2,
    content3,
    content4,
    data = centralizers.tableData.data,
    columns = centralizers.tableData.columns,
  } = centralizers

  return (
    <div>
      <HeroProduct title="Centralizers" image={`url(${imagePath + 'alcci2.JPG'})`} />
      <Card title={content1.title} body={content1.body} left delay={100} />
      <CardList title={content2.title} body={content2.body} left delay={200} />
      <CardList title={content3.title} body={content3.body} left delay={300} />
      <CardList title={content4.title} body={content4.body} left delay={400} />
      <Table data={data} columns={columns} />
    </div>
  )
}
