export const shieldMLE = {
  content1: {
    title: 'Overview',
    body: [
      `The Specialized Oil Tools Patent Pending MLE Shield™ is designed to ensure proper fit and protection of Motor Lead Extension (MLE) Cables and Control Lines during the
      installation and operation of Electrical Submersible Pumps (ESP's).`,
      `Optimized for all ESP applications the MLE Shield provides superior service and life in shallow, extended, deviated, sour service, and tight clearanced downhole environments.`,
    ],
  },
  content2: {
    title: 'Applications',
    body: [
      'Land Based and Offshore ESP Applications',
      'Conventional and Unconventional wells',
      'Downhole equipment requiring cable management',
      `Intelligent Completions`,
      `Chemical Treatments applications`,
      `Fiber Optic Cables`,
      `Downhole Electric Cables`,
    ],
  },
  content3: {
    title: 'Benefits',
    body: [
      'Physically secures a variety of cable types preventing them from being damaged ensuring proper deployment and operation',
      'ZERO loose parts for fast and reliable installation',
      'Field adjustable to accommodate last minute configuration changes',
      'Reduces risk of hangups during installation and removal',
      'Rotationally locked and vibration resistant to minimizes risk of loosening and damaging cables during deployment',
    ],
  },
  content4: {
    title: 'Features',
    body: [
      'ZERO LOOSE PARTS',
      'Compact design with multiple materials for multiple well environments and NACE compliance',
      'Fits all ESP manufacturer sizes and applications',
      'Optimized sizing to prevent loosening and movement of cables',
      'Fast and easy installation process',
      'Capable of supporting 100+ ft (~31m) of hanging cable weight',
    ],
  },
}
