import React, { Component } from 'react'
import { Fade } from 'react-reveal'
import ReactSVG from 'react-svg'

import { SloganBanner } from './'

const imageBack = () => {
  return (
    <div className="hero-banner-image-container">
      <div className="hero-banner-image-filter" />
      <div className="hero-banner-image" />
    </div>
  )
}

const newContent = () => {
  return (
    <Fade>
      <div>
        <div className="hero-load-container">
          <ReactSVG src="/images/logo-sot.svg" className="logo-img" />
          <p className="title">Specialized Oil Tools</p>
        </div>
      </div>
    </Fade>
  )
}

export class Hero extends Component {
  constructor(props) {
    super(props)
    this.state = { timed: false }
  }

  componentDidMount() {
    setTimeout(() => this.setState({ timed: true }), 2250)
  }

  fadingMessage() {
    const { timed } = this.state
    const { text } = this.props

    return (
      <Fade bottom duration={2000}>
        <div>
          <div className="hero-fade-message">
            {timed ? (
              newContent()
            ) : (
              <div>
                <div /> <p className="vxt">{text}</p>
              </div>
            )}
          </div>
        </div>
      </Fade>
    )
  }

  render() {
    const { timed } = this.state

    return (
      <div className="hero-banner-container">
        {imageBack()}
        {this.fadingMessage()}
        {timed && <SloganBanner />}
      </div>
    )
  }
}
