import React from 'react'
import { useTable } from 'react-table'
import Fade from 'react-reveal/Fade'

const TableComponent = ({ columns, data }) => {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <Fade>
      <table {...getTableProps()} className="table-product">
        <thead className="table-title">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                      <p>{cell.render('Cell')}</p>
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </Fade>
  )
}

export function Table({ columns, data }) {
  const columnsMemoized = React.useMemo(() => [columns], [])

  return <TableComponent columns={columnsMemoized} data={data} />
}
