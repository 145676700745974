import React from 'react'

import { Card, CardList, HeroProduct, Table } from '../components'
import { chemicalInjectorAnnular } from '../contentData'

export const ChemicalInjectionAnnularPage = () => {
  const {
    content1,
    content2,
    content3,
    content4,
    data = chemicalInjectorAnnular.tableData.data,
    columns = chemicalInjectorAnnular.tableData.columns,
  } = chemicalInjectorAnnular

  return (
    <div>
      <HeroProduct
        title="Centralizing Annular Chemical Injector"
        image={`url(${window.location.origin + '/images/alcci2.JPG'})`}
      />
      <Card title={content1.title} body={content1.body} left delay={100} />
      <CardList title={content2.title} body={content2.body} left delay={200} />
      <CardList title={content3.title} body={content3.body} left delay={300} fix />
      <CardList title={content4.title} body={content4.body} left delay={400} />
      <Table data={data} columns={columns} />
    </div>
  )
}
