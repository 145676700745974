import React from 'react'

import { TableComponent, Form } from '../components'
import { chemicalInjectorAnnular } from '../contentData'

export const ContactPage = () => {
  const { tableData } = chemicalInjectorAnnular
  return (
    <div>
      {/* <TableComponent columns={tableData.columns} data={tableData.data} /> */}
      <Form />
    </div>
  )
}
