import React from 'react'

import WufooForm from 'react-wufoo-embed'

export const Form = () => (
  <div className="form-container">
    <iframe
      height="517"
      title="Contact Form"
      allowtransparency="true"
      frameBorder="0"
      scrolling="no"
      style={{ backgroundColor: '#fff', display: 'flex' }}
      src="https://SpecializedOilTools.formstack.com/forms/contact_form"
    ></iframe>
  </div>
)
