import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import ReactHtmlParser from 'react-html-parser'

import { isSafari } from '../utils'

export const Card = ({
  title,
  body,
  addClassName = '',
  delay = 0,
  top = false,
  left = false,
  right = false,
  bottom = false,
  fix = false,
}) => {
  return (
    <Fade bottom delay={delay} top={top} left={left} bottom={bottom} right={right}>
      <div className={`${isSafari && fix && 'safari-card'}`}>
        <div className={`card-container${addClassName && ' ' + addClassName}`}>
          <div className="col-1">
            <div className="title-bar center">
              <h2>{title}</h2>
            </div>
          </div>
          <div className="col-3">
            {body.map((bod, i) => {
              return <p key={`${title}-cardBody-${i}`}>{ReactHtmlParser(bod)}</p>
            })}
          </div>
        </div>
      </div>
    </Fade>
  )
}
