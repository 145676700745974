import { createStore, combineReducers } from 'redux'
import { visuals, navigation, products } from '../reducers'

export default () => {
  const store = createStore(
    combineReducers({
      visuals,
      navigation,
      products,
    }),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  )

  return store
}
