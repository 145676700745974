export const chemicalInjectorCentralizing = {
  content1: {
    title: 'Overview',
    body: [
      'The Specialized Oil Tools Centralizing Chemical Injector (CCI) is designed to serve as direct centralization to the ESP with added benefit of being able to inject chemicals directly below the ESP during production. This product can be ran directly below the ESP or between producing zones in wells that have multiple ESP’s installed.',
    ],
  },
  content2: {
    title: 'Applications',
    body: [
      'Land Based and Offshore ESP Applications',
      'All well types including vertical, highly deviated, and horizontal',
      'Single, multiple, shrouded, and POD ESP installations',
      'Wells needing corrosion, organic inhibition and flow assurance chemicals',
      ['Paraffin', 'Asphaltene', 'Hydrates', 'Scale', 'Demulsification', 'Defoaming'],
    ],
  },
  content3: {
    title: 'Benefits',
    body: [
      'Reduces the risk of corrosion spots with full 360° injection when compared to single point injection',
      'Mitigates risk of hangups and issues during installation',
      'Increases ESP life and performance over the life of the well',
      'Bullnose and Threaded downhole connections avaliable allowing for equipment to be run below the CCI.',
      'Unique design eliminates the “U Tube Effect” with an internal valve',
    ],
  },
  content4: {
    title: 'Features',
    body: [
      'Compact design with multiple materials for multiple well environments and NACE compliance',
      'Full 360° injection area',
      'Up to 10,000 psi back pressure resistance',
      '3.5-7 GPM flow rates available',
    ],
  },
  tableData: {
    columns: {
      Header: 'Centralizing Annular Chemical Injector',
      columns: [
        {
          Header: 'CACI Size (Series)',
          accessor: 'size',
        },
        {
          Header: '**CACI OD(in)',
          accessor: 'in',
        },
        {
          Header: 'Casing Size (in)',
          accessor: 'casing',
        },
        {
          Header: 'Casing Weight (lbs/ft)',
          accessor: 'weight',
        },
        {
          Header: 'Control Line Size (in)',
          accessor: 'line',
        },
        {
          Header: 'Metallurgy',
          accessor: 'metallurgy',
        },
      ],
    },
    data: [
      {
        size: 500,
        in: 4.13,
        casing: 5,
        weight: '11.5-18',
        line: '1/4-3/8',
        metallurgy: '*Carbon Steel & Stainless Steel',
      },
      {
        size: 550,
        in: 4.5,
        casing: 5.5,
        weight: 23,
        line: '1/4-3/8',
        metallurgy: '*Carbon Steel & Stainless Steel',
      },
      {
        size: 550,
        in: 4.6,
        casing: 5.5,
        weight: '13-20',
        line: '1/4-3/8',
        metallurgy: '*Carbon Steel & Stainless Steel',
      },
      {
        size: 700,
        in: 5.5,
        casing: 7,
        weight: '32-38',
        line: '1/4-3/8',
        metallurgy: '*Carbon Steel & Stainless Steel',
      },
      {
        size: 700,
        in: 5.62,
        casing: 7,
        weight: '26-32',
        line: '1/4-3/8',
        metallurgy: '*Carbon Steel & Stainless Steel',
      },
      {
        size: 700,
        in: 5.75,
        casing: 7,
        weight: '17-26',
        line: '1/4-3/8',
        metallurgy: '*Carbon Steel & Stainless Steel',
      },
    ],
  },
}
