import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Fade } from 'react-reveal'
import Jump from 'react-reveal/Jump'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { Button } from '../components'
import { category } from '../contentData'
import { gridFromArray } from '../utils'
import { _onProductCategory } from '../actions'

export class products extends Component {
  constructor(props) {
    super(props)
  }

  componentWillUnmount() {
    const { _onProductCategory } = this.props
    _onProductCategory('')
  }

  productCard(title, category, image, dest, right = false) {
    const { _onProductCategory } = this.props

    return (
      <div>
        <div
          className={`products-card center${right ? ' align-end' : ' align-start'} `}
          onClick={() => {
            _onProductCategory(category)
          }}
        >
          <div className="title-bar">
            <h3>{title}</h3>
          </div>
          <div className={`products-back products-back-image-${image}`} />
        </div>
      </div>
    )
  }

  productCategory() {
    return (
      <div className="row">
        <Jump left>{this.productCard('Production Enhancement', 'prod', 'production')}</Jump>
        <Jump right>{this.productCard('Well Safety Systems', 'safety', 'safety', null, true)}</Jump>
      </div>
    )
  }

  productBody(selected) {
    const { productCategory } = this.props

    return (
      <div className="col-3">
        {selected.map((val, i) => (
          <div key={`products-selected-row-${i}`} className="row">
            {val.map((v, i) => (
              <Fade bottom key={`product-component-category-${i}`} delay={i * 100}>
                <Link to={v.link} className="products-card" onClick={() => window.scrollTo(0, 0)}>
                  <div className="products-card-content">
                    <p className="bold">{v.title}</p>
                    <p className="products-card-summary">{v.summary}</p>
                  </div>
                  <div className={`products-back ${productCategory}`} />
                </Link>
              </Fade>
            ))}
          </div>
        ))}
      </div>
    )
  }

  productSelected() {
    const { _onProductCategory, productCategory, windowMode } = this.props
    const { production, safety } = category

    let selected = []
    let selectMSG = ''

    if (productCategory === 'safety') {
      selected = safety
      selectMSG = 'Well Safety Systems'
    } else if (productCategory === 'prod') {
      selected = production
      selectMSG = 'Production Enhancement'
    } else {
      selected = []
      selectMSG = ''
    }

    const isMobile = windowMode === 'mobile' ? 1 : 2

    selected = gridFromArray(selected, isMobile)

    return (
      <div key={productCategory}>
        <div className="col-1">
          <Fade top>
            <div className="products-bar products-header title-bar">
              <h2>{selectMSG}</h2>
            </div>
          </Fade>
        </div>
        {this.productBody(selected)}
        <div className="col-1">
          <Button
            text="Go Back"
            onClick={() => {
              _onProductCategory('')
            }}
            icon={faArrowLeft}
          />
        </div>
      </div>
    )
  }

  render() {
    const { productCategory } = this.props
    return (
      <div className={`products-container ${productCategory ? 'products-open' : ''}`}>
        {!productCategory ? (
          <div>
            <Fade>
              <div className="title-bar category-bar center">
                <h2>Our Product Lines</h2>
              </div>
            </Fade>
            {this.productCategory()}
          </div>
        ) : (
          this.productSelected()
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  productCategory: state.products.productCategory,
  windowMode: state.visuals.windowMode,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ _onProductCategory }, dispatch)
}
export const Products = connect(
  mapStateToProps,
  mapDispatchToProps,
)(products)
