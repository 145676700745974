export const centralizers = {
  content1: {
    title: 'Overview',
    body: [
      `The Specialized Oil Tools Centralizers are designed to be ran in conjunction with tubing and pipe by threading directly onto the string to provide optimum centralization.`,
      `They decrease installation and conveyance risks due to hangups in vertical, deviated, and horizontal wells.`,
    ],
  },
  content2: {
    title: 'Applications',
    body: [
      `Production Tubing`,
      'Coiled Tubing',
      'Stick Pipe',
      'ESP and other Tool Strings',
      'Intervention & Fishing Operations',
    ],
  },
  content3: {
    title: 'Benefits',
    body: [
      `Reduces the risk of hangups and excessive conveyance forces during
      installation and intervention`,
      'Ran directly inline with tubing or pipe to reduce installation time saving man hours',
      'Minimizes drag forces',
      'Smooth transitions to glide over restriction',
      'Maximized flow area and position increase production efficiency',
    ],
  },
  content4: {
    title: 'Features',
    body: [
      `Compact design with multiple materials for multiple well environments and
      NACE compliance`,
      'Rigid construction provides positive standoff of tubing & pipe',
      'Rugged design to work in the most demanding applications',
    ],
  },
  tableData: {
    columns: {
      Header: 'Tubing & Pipe Centralizer',
      columns: [
        {
          Header: 'Centralizer Size (Series)',
          accessor: 'size',
        },
        {
          Header: 'Centralizer OD (in)',
          accessor: 'in',
        },
        {
          Header: 'Casing Size (in)',
          accessor: 'casing',
        },
        {
          Header: 'Casing Weight (lbs/ft)',
          accessor: 'weight',
        },
        {
          Header: 'Casing Drift',
          accessor: 'drift',
        },
        {
          Header: 'Metallurgy',
          accessor: 'metallurgy',
        },
      ],
    },
    data: [
      {
        size: 500,
        in: 4.56,
        casing: 5.5,
        weight: 20,
        drift: 4.653,
        metallurgy: 'L80 & Carbon Steel',
      },
      {
        size: 500,
        in: 4.5,
        casing: 5.5,
        weight: 23,
        drift: 5.45,
        metallurgy: 'L80 & Carbon Steel',
      },
      {
        size: 700,
        in: 5.8,
        casing: 7,
        weight: 32,
        drift: 5.969,
        metallurgy: 'L80 & Carbon Steel',
      },
      {
        size: 700,
        in: 5.97,
        casing: 7,
        weight: 29,
        drift: 6.059,
        metallurgy: 'L80 & Carbon Steel',
      },
      {
        size: 700,
        in: 6.04,
        casing: 7,
        weight: 26,
        drift: 6.151,
        metallurgy: 'L80 & Carbon Steel',
      },
    ],
  },
}
