import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Card, CardList, Form } from '../components'
import { services } from '../contentData'

class servicesPage extends Component {
  render() {
    const { windowMode } = this.props
    const { content2, content3 } = services
    return (
      <div className="content-wrapper" key={`service-page-${windowMode}`}>
        <div>
          <div className="row">
            <CardList title={content2.title} body={content2.body} left />
            {windowMode === 'desktop' && (
              <CardList title={content3.title} body={content3.body} right />
            )}
          </div>
          {windowMode === 'mobile' && (
            <div className="row">
              <CardList title={content3.title} body={content3.body} right />
            </div>
          )}
          <Form />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({ windowMode: state.visuals.windowMode })

export const ServicesPage = connect(mapStateToProps)(servicesPage)
