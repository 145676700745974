import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Jump from 'react-reveal/Jump'
import Fade from 'react-reveal/Fade'
import { NavLink } from 'react-router-dom'
import { faDownload, faCommentDots } from '@fortawesome/free-solid-svg-icons'

const bucketData = [
  {
    icon: faDownload,
    text: 'Get the PDF - Download Our Brochure',
    link: '/docs/Specialized_Oil_Tools_Product_Catalog_2020.pdf',
  },
  { icon: faCommentDots, text: 'Get a Quote in 24 Hours - Contact Us', link: '/contact' },
]

const bucket = (icon, text, i, link) => (
  <Jump key={`cta-bucket-${i}`} delay={i > 0 ? i * 100 : 0}>
    <div>
      <a className="cta-bucket" href={link} target="_blank">
        <div className="cta-bucket-calibrate">
          <FontAwesomeIcon icon={icon} className="nav-icon" />
          <p>{text}</p>
        </div>
      </a>
    </div>
  </Jump>
)

const hotFix = (icon, text, i, link) => (
  <Jump key={`cta-bucket-${i}`} delay={i > 0 ? i * 100 : 0}>
    <div>
      <NavLink className="cta-bucket" to={link} onClick={() => window.scrollTo(0, 0)}>
        <div className="cta-bucket-calibrate">
          <FontAwesomeIcon icon={icon} className="nav-icon" />
          <p>{text}</p>
        </div>
      </NavLink>
    </div>
  </Jump>
)

export const BarCTA = () => (
  <Fade>
    <div className="cta-container">
      <div className="row">
        {bucketData.map((val, i) => {
          if (i === 0) {
            return bucket(val.icon, val.text, i, val.link)
          } else {
            return hotFix(val.icon, val.text, i, val.link)
          }
        })}
      </div>
    </div>
  </Fade>
)
