import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import ReactSVG from 'react-svg'
import { Fade } from 'react-reveal'

import { category } from '../contentData'
import { _onWindow, _onMobileNav, _onProductCategory } from '../actions'
import { isSafari } from '../utils'

class mobile_Nav extends Component {
  constructor(props) {
    super(props)
    this.state = { navStatus: false }
  }

  navItem(to, title, key = null) {
    const { _onMobileNav } = this.props

    return (
      <NavLink
        to={to}
        activeClassName="active"
        onClick={() => {
          window.scrollTo(0, 0), this.setState({ navState: false }), _onMobileNav(false)
        }}
        key={key !== null ? key : undefined}
        className="header-nav-item"
      >
        <p>{title}</p>
      </NavLink>
    )
  }

  navProducts() {
    const { navState } = this.state
    const { safety, production } = category
    const isOpen = navState ? 'open' : 'close'

    return (
      <div className={`nav-products ${isOpen}`}>
        <div className="nav-products-body">
          {production.map((val, i) =>
            this.navItem(val.link, val.title, `mobile-production-nav-${i}`),
          )}
          {safety.map((val, i) => this.navItem(val.link, val.title, `mobile-safety-nav-${i}`))}
        </div>
      </div>
    )
  }

  navItemHeader() {
    const { navState } = this.state
    const { _onMobileNav } = this.props

    return (
      <div
        className="nav-products-container"
        onClick={() => {
          window.scrollTo(0, 0), this.setState({ navState: !navState })
        }}
      >
        <div className="row nav-products-header header-nav-item">
          <a className="row">
            <div className="col-1">
              <p>Products</p>
            </div>
            <div className="col-1">
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </a>
        </div>
      </div>
    )
  }

  navigation() {
    const { _onMobileNav, mobileNav, _onProductCategory } = this.props
    const { navStatus } = this.state

    return (
      <div className="col-4">
        <nav className="nav">
          <NavLink
            to="/"
            activeClassName="is-active"
            exact={true}
            onClick={() => {
              _onProductCategory(''), _onMobileNav(false), this.setState({ mobileNav: false })
            }}
            className="mobile-nav-logo"
          >
            <ReactSVG src="/images/logo-sot.svg" className="logo-img" />
          </NavLink>
          {this.navItemHeader()}
          {this.navProducts()}
          {this.navItem('/about', 'About')}
          {this.navItem('/services', 'Services')}
          {this.navItem('/contact', 'Contact')}
        </nav>
      </div>
    )
  }

  navBars() {
    const { _onMobileNav } = this.props
    return (
      <div className="mobile-nav-bars" onClick={() => _onMobileNav(false)}>
        <FontAwesomeIcon icon={faTimes} className="mobile-nav-bars-x" />
      </div>
    )
  }

  render() {
    const { mobileNav } = this.props

    if (mobileNav) {
      return (
        <Fade duration={250}>
          <div className={`mobile-nav-container${isSafari ? ' safari-fix' : ''}`}>
            {this.navBars()}
            {this.navigation()}
          </div>
        </Fade>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = state => ({ mobileNav: state.navigation.mobileNav })

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ _onWindow, _onMobileNav, _onProductCategory }, dispatch)
}
export const MobileNav = connect(
  mapStateToProps,
  mapDispatchToProps,
)(mobile_Nav)
