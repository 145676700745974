export const services = {
  content1: {
    title: 'Services',
    body: [
      'Converging market forces including shale production, lower oil prices, new operational efficiencies, geopolitical developments and more – are dramatically reshaping the global oil and gas industry.',
      'As the pace of change accelerates, companies must make the right choices in order to identify production opportunities that promise optimal growth while also prioritizing investments, driving innovation, managing risk and reducing costs.',
      'Specialized Oil Tools is ready to partner with you to meet these challenges by combining engineering expertise and analytics into decision-support solutions that are specialized to your unique applications.',
    ],
  },
  content2: {
    title: 'Engineering & Design',
    body: [
      'New Product Development',
      'Conceptual/Feasibility Studies',
      'Front End Engineering Design',
      'Finite Element Analysis (FEA)',
      'Computation Fluid Dynamics (CFD)',
      'Fracture Mechanics',
      'Failure Mode Effect Analysis (FMEA)',
    ],
  },
  content3: {
    title: 'Customer Support Services',
    body: [
      'Customer Focused Solutions',
      'Customer Engagement',
      'Operational Support',
      'Operation Training & Education',
      'Quality Program Implementation & Management',
      'Vendor Management',
      'Customer Relations',
    ],
  },
}
