export const shrouds = {
  content1: {
    title: 'Overview',
    body: [
      'Specialized Well Production Systems focuses on production enhancement and increasing the longevity of downhole production equipment.',
      'ESP shrouds are used to increase the velocity of produced fluids around the ESP motor, increasing motor cooling, performance, and life.',
    ],
  },
  content2: {
    title: 'Applications',
    body: [
      'Land Based and Offshore ESP Applications',
      'ESP applications where motor overheating is a concern',
      'All well types including vertical, highly deviated, and horizontal',
      'Sandy and high temperature wells',
    ],
  },
  content3: {
    title: 'Benefits',
    body: [
      'Increases fluid velocity to increase motor cooling and prolong motor life',
      'Few overall parts for fast and simple instillation',
      'Modular design allows for multiple configurations and system lengths',
      'Flush joint connections reduce drag during run in',
    ],
  },
  content4: {
    title: 'Features',
    body: [
      "Multiple metallurgy's from alloy steel to 13% Cr for the most demanding applications",
      'Simple construction allows for easy instillation',
      'Protects MLE cables and capillary tubing',
      'Can be run in conjunction with Specialized chemical treatment tools',
    ],
  },
}
