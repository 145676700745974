export const category = {
  safety: [
    {
      title: 'MLE Shield™ - Premium Cable Protection',
      summary:
        'The Specialized Oil Tools Patent Pending MLE Shield™ is designed to ensure proper fit and protection.',
      link: '/products/mle-shield',
    },
    {
      title: 'Cross Coupling Clamps',
      summary:
        'ESP Motor Lead Extension (MLE) Cable and Control Line damage are a major concern when deploying ESP equipment.',
      link: '/products/coupling-clamps',
    },
    {
      title: 'Centralizers',
      summary:
        'The Specialized Oil Tools Centralizers are designed to be ran in conjunction with tubing and pipes.',
      link: '/products/centralizers',
    },
    {
      title: 'ESP Safety Shield™ - Enhanced Sand Fallback Protection',
      summary:
        'The Well Safety Systems product line is centered around ESP sand fall back protection with the Sand Safety Shield.',
      link: '/products/sand-safety-shield',
    },
  ],
  production: [
    {
      title: 'Centralizing Chemical Injector',
      summary:
        'The Specialized Oil Tools Centralizing Chemical Injector (CCI) is designed to serve as direct centralization to the ESP.',
      link: '/products/chemical-injection',
    },
    {
      title: 'Annular Chemical Injection',
      summary:
        'The Specialized Oil Tools Centralizing Annular Chemical Injector is engineered to perform in tight clearance ESP applications',
      link: '/products/chemical-injection-annular',
    },
    {
      title: 'Shrouds',
      summary:
        'Specialized Well Production Systems focuses on production enhancement and increasing the longevity of downhole production equipment.',
      link: '/products/shrouds',
    },
  ],
}
