import React, { Component } from 'react'
import { Fade } from 'react-reveal'
import { connect } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'

import { slogans } from '../contentData'

const slogansAMT = slogans.length - 1

class sloganBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sloganItem: 0,
    }
  }

  timer = 0

  componentDidMount() {
    this.timer = setInterval(() => {
      if (this.state.sloganItem >= slogansAMT) {
        this.setState({ sloganItem: 0 })
        return
      }

      this.setState(prevState => {
        return {
          sloganItem: prevState.sloganItem + 1,
        }
      })
    }, 3500)
  }

  componentWillUnmount() {
    this.setState({ sloganItem: 0 })
    return clearInterval(this.timer)
  }

  renderBanner() {
    const { sloganItem } = this.state

    return (
      <div className="title-bar category-bar slogan-banner">
        <Fade key={`slogans-${sloganItem}`} duration={1500}>
          <h2>{ReactHtmlParser(slogans[sloganItem])}</h2>
        </Fade>
      </div>
    )
  }

  render() {
    const { mobileNav } = this.props

    if (!mobileNav) {
      return this.renderBanner()
    } else {
      return null
    }
  }
}

const mapStateToProps = state => ({ mobileNav: state.navigation.mobileNav })

export const SloganBanner = connect(mapStateToProps)(sloganBanner)
