import React, { Component } from 'react'
import { Fade } from 'react-reveal'
import { imagePath } from '../utils'

const heroImage = image => (
  <div className="hero-product-back">
    <div className="hero-product-back-filter" />
    <div className="hero-product-back-image" style={{ backgroundImage: image }}></div>
  </div>
)

const heroContent = title => (
  <div className="hero-product-content">
    <div className="hero-product-title">
      <h2>{title}</h2>
    </div>
  </div>
)

export class HeroProduct extends Component {
  constructor(props) {
    super(props)
    this.state = { selected: 0 }
  }

  gallery(array) {
    const { selected } = this.state

    return (
      <div className="hero-product-gallery-container">
        <div className="hero-product-gallery-image col-3">
          <Fade key={`reset-img-${selected}`}>
            <img src={imagePath + array[selected]} alt={array[selected]} />
          </Fade>
        </div>
        <div className="hero-product-gallery-selector row">
          {array.map((val, i) => {
            return (
              <div
                key={`hpgs-${i}`}
                className="hero-product-gallery-select"
                onClick={() => this.setState({ selected: i })}
              >
                <img src={imagePath + val} alt={val} />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  render() {
    const { key = null, image, title, gallery = null } = this.props

    return (
      <Fade top key={key ? key : null}>
        <div className={`hero-product-container${!gallery ? ' hero-no-img' : ''}`}>
          {heroImage(image)}
          {heroContent(title)}
          {gallery && this.gallery(gallery)}
        </div>
      </Fade>
    )
  }
}
