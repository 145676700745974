import { WINDOW } from '../utils'

const INITIAL_STATE = {
  windowSize: 0,
  windowMode: '',
}

const detectWindowSize = val => {
  return val <= 1150 ? 'mobile' : 'desktop'
}

export const visuals = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WINDOW:
      return {
        ...state,
        windowSize: action.payload,
        windowMode: detectWindowSize(action.payload),
      }
    default:
      return { ...state }
  }
}
