import React from 'react'
import Fade from 'react-reveal/Fade'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseye } from '@fortawesome/free-solid-svg-icons'

import { isSafari } from '../utils'

const listItem = (val, key, icon) => (
  <li key={key} className="card-list-item">
    <FontAwesomeIcon icon={icon} />
    <p>{val}</p>
  </li>
)

export const CardList = ({
  title,
  body,
  delay = 0,
  top = false,
  left = false,
  right = false,
  bottom = false,
  icon = faBullseye,
  fix = false,
}) => {
  return (
    <Fade bottom delay={delay} top={top} left={left} bottom={bottom} right={right}>
      <div className={`${isSafari && fix && 'safari-card'}`}>
        <div className="card-container card-container-list">
          <div className="col-1">
            <div className="title-bar center">
              <h2>{title}</h2>
            </div>
          </div>
          <div className="col-3">
            <ul>
              {body.map((val, i) => {
                if (Array.isArray(val)) {
                  return (
                    <ul key={`ul-${val}-${i}`}>
                      {val.map((item, int) => listItem(item, `${title}-sublist-${int}`, icon))}
                    </ul>
                  )
                } else {
                  return listItem(val, `${title}-list-${i}`, icon)
                }
              })}
            </ul>
          </div>
        </div>
      </div>
    </Fade>
  )
}
