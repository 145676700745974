export const dashBoard = {
  content1: {
    title: 'Customer Service',
    body: [
      'It is our core belief that our continued growth and success solely depends on customer satisfaction and loyalty. We are committed to consistently deliver the highest caliber customer service that will meet and exceed our customers’ requirements and expectations.',
    ],
  },
  content2: {
    title: 'Experienced Team',
    body: [
      'Our team takes the lessons learned from over 30 years of Oilfield experience with the sole intent of providing superior products and service to our customers and partners. We specialize in delivering premium solutions in areas where price matters and providing head-to-toe support and customer service that traditionally gets overlooked by the main market supplier.',
    ],
  },
  content3: {
    title: 'Extensive Supply Chain & Manufacturing Network',
    body: [
      'We specifically created our supply chain to have as few bottle necks as possible, as a result we are able to consistently deliver quality products in a timely manner. Our ever growing network of ISO 9001 machine shops and material suppliers reaffirms our commitment to never being the reason a job was delayed.',
      'All of our products are <span class="usa">100% MADE IN THE USA</span>, supporting local shops and suppliers.',
    ],
  },
}
