import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { AboutPage, ContactPage, DashboardPage, NotFoundPage, ServicesPage } from '../screens'
import { ProductsRoute } from './ProductsRoute'
import { Footer, Header, Hero, MobileNav } from '../components'
import { isSafari } from '../utils'

const renderPage = component => {
  return <div className="content-wrapper">{component()}</div>
}

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <div className={`content-container ${isSafari ? 'safari-fix' : ''}`}>
        <Header />
        <Switch>
          <Route path="/" exact>
            <Hero text="Do You Even Lift?" />
          </Route>
        </Switch>
        <Switch>
          <Route path="/about" component={AboutPage} />
          <Route path="/contact" children={() => renderPage(ContactPage)} />
          <Route path="/products" component={ProductsRoute} />
          <Route path="/services" component={ServicesPage} />
          <Route path="/" children={() => renderPage(DashboardPage)} exact />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
      <Footer />
      <MobileNav />
    </BrowserRouter>
  )
}
