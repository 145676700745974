import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import ReactSVG from 'react-svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faBars } from '@fortawesome/free-solid-svg-icons'

import { _onMobileNav, _onWindow, _onProductCategory } from '../actions'
import { category } from '../contentData'
import { isSafari } from '../utils'

class header extends Component {
  constructor(props) {
    super(props)
    this.state = { navStatus: false }
    this.resize = this.resize.bind(this)
  }

  resize() {
    const { _onWindow } = this.props
    _onWindow(window.innerWidth)
  }

  componentDidMount() {
    this.resize()
    window.addEventListener('resize', this.resize)
  }

  navItem(to, title, key = null) {
    return (
      <NavLink
        to={to}
        activeClassName="active"
        onClick={() => {
          window.scrollTo(0, 0), this.setState({ navState: false })
        }}
        key={key !== null ? key : undefined}
        className="header-nav-item"
      >
        <p>{title}</p>
      </NavLink>
    )
  }

  navProducts() {
    const { navState } = this.state
    const { safety, production } = category
    const isOpen = navState ? 'open' : 'close'

    return (
      <div className="nav-products">
        <div className={`nav-products-body ${isOpen}`}>
          {production.map((val, i) => this.navItem(val.link, val.title, `production-nav-${i}`))}
          {safety.map((val, i) => this.navItem(val.link, val.title, `safety-nav-${i}`))}
        </div>
      </div>
    )
  }

  navItemProducts() {
    const { navState } = this.state

    return (
      <div
        className="nav-products-container header-nav-item"
        onClick={() => {
          window.scrollTo(0, 0), this.setState({ navState: !navState })
        }}
      >
        <div className="row nav-products-header">
          <a className="row">
            <div className="col-2">
              <p>Products</p>
            </div>
            <div className="col-1">
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </a>
        </div>
      </div>
    )
  }

  //Todo fix the nested menu height: Example found in Safari

  navigation() {
    const { _onMobileNav, windowMode } = this.props

    const navDsk = (
      <div className="col-4">
        <nav className="row nav">
          {this.navItemProducts()}
          {this.navItem('/about', 'About')}
          {this.navItem('/services', 'Services')}
          {this.navItem('/contact', 'Contact')}
        </nav>
        {this.navProducts()}
      </div>
    )

    const navMbl = (
      <div className="col-4">
        <div
          className="nav"
          onClick={() => {
            _onMobileNav(true), window.scrollTo(0, 0)
          }}
        >
          <FontAwesomeIcon icon={faBars} className="nav-bars" />
        </div>
      </div>
    )

    return windowMode === 'desktop' ? navDsk : navMbl
  }

  render() {
    const { _onProductCategory } = this.props

    return (
      <header className="row header">
        <div className="col-1 nav" style={{ backgroundColor: '#fff' }}>
          <NavLink
            to="/"
            className="logo-link"
            activeClassName="is-active"
            exact={true}
            onClick={() => {
              this.setState({ navState: false }), _onProductCategory('')
            }}
          >
            <ReactSVG src="/images/logo-sot.svg" className="logo-img" />
          </NavLink>
        </div>
        {this.navigation()}
      </header>
    )
  }
}

const mapStateToProps = state => ({
  windowSize: state.visuals.windowSize,
  windowMode: state.visuals.windowMode,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ _onMobileNav, _onWindow, _onProductCategory }, dispatch)
}
export const Header = connect(
  mapStateToProps,
  mapDispatchToProps,
)(header)
