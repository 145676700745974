export const couplingClamp = {
  content1: {
    title: 'Overview',
    body: [
      `ESP Motor Lead Extension (MLE) Cable and Control Line damage are a major concern when deploying ESP equipment. Specialized Oil Tools is proud to offer Cross Coupling Clamps designed to be rugged and reuseable for multiple ESP runs and long service life.Optimized for all ESP applications cross coupling clamps offer superior protection for your ESP MLE cables and control lines.`,
    ],
  },
  content2: {
    title: 'Applications',
    body: [
      'Land Based and Offshore ESP Applications',
      'Conventional and Unconventional wells',
      'Downhole equipment requiring cable management',
      `Intelligent Completions`,
      `Chemical Treatments applications`,
      `Fiber Optic Cables`,
      `Downhole Electric Cables`,
    ],
  },
  content3: {
    title: 'Benefits',
    body: [
      'Physically secures a variety of cable types preventing them from being damaged ensuring proper deployment and operation',
      'ZERO loose parts for fast and reliable installation',
      'Designed to reduce hangups and equipment damage during installation and removal of equipment',
      'Rotationally locked and vibration resistant to minimizes risk of loosening and damaging cables during deployment',
    ],
  },
  content4: {
    title: 'Features',
    body: [
      'ZERO LOOSE PARTS',
      'Extremely durable metallurgy to wistand heavy impacts and prevent shattering',
      'Optimized sizing to prevent loosening and movement of cables and capillary tubes',
      'Fast and easy installation process',
      'Fast and easy installation process',
      'Refurbishable and capeable of multiple runs reducing over all cost',
    ],
  },
}
