export const about = {
  content1: {
    title: `Our Story`,
    body: [
      'Specialized Oil Tools was founded out of the frustration witnessed working under organizations who failed to recognize the needs of their customers. We believe that people-suppliers-and customers should be treated with respect.',

      'That is why it is our core belief that our continued growth and success solely depends on customer satisfaction and loyalty.',
      'We are committed to consistently deliver the highest caliber customer service that will meet and exceed our customers’ requirements and expectations.',

      'Specialized Oil Tools provides customers with more options, cost-effective solutions and services. We listen to customer needs and develop customized solutions for their unique applications.',
      ' Providing head-to-toe support and customer service and support that traditionally gets overlooked.',
    ],
  },
  content2: {
    title: `Our Mission`,
    body: [
      `Specialized Oil Tools’ mission is to provide customers with engagement and solutions that address their current and future needs. We will steward innovation by providing value added customer focused solutions. `,
    ],
  },
  content3: {
    title: `Our Vision`,
    body: [
      `Specialized Oil Tools strives to provide our employees and customers with future focused solutions, innovations, and growth opportunities. To move past the current limitations of technology, establishing a positive and long-lasting impact.`,
    ],
  },
  content4: {
    title: `Our Services`,
    body: [
      'Our extensive design experience, customer focused approach, and efficient infrastructure allow us to quickly engineer and manufacture specialized solutions from concept to end product.',
      'Ensuring that we will never be a bottleneck or the reason a job was delayed.',
    ],
  },
}
