import React from 'react'
import { Card, Products, BarCTA, SloganBanner } from '../components'
import { dashBoard } from '../contentData'

export const DashboardPage = () => {
  const { content1, content2, content3 } = dashBoard
  return (
    <div>
      <Products />
      <Card title={content1.title} body={content1.body} top />
      <Card title={content2.title} body={content2.body} top />
      <Card title={content3.title} body={content3.body} top />
      <BarCTA />
    </div>
  )
}
