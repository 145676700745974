import React, { Component } from 'react'
import { Route } from 'react-router-dom'

import {
  CableProtectionPage,
  CentralizersPage,
  ChemicalInjectionPage,
  ChemicalInjectionAnnularPage,
  CouplingClampsPage,
  GasLiftSystems,
  ProductsPage,
  SandSafetyShieldPage,
  ShieldMLEPage,
  ShroudsPage,
  WellCleanupPage,
  YToolSystemsPage,
} from '../screens'

export class ProductsRoute extends Component {
  render() {
    const { path } = this.props.match

    return (
      <div className="content-wrapper products-path">
        <Route exact path={path} component={ProductsPage} />
        <Route path={`${path}/mle-shield`} component={ShieldMLEPage} />
        <Route path={`${path}/coupling-clamps`} component={CouplingClampsPage} />
        <Route path={`${path}/chemical-injection`} component={ChemicalInjectionPage} />
        <Route
          path={`${path}/chemical-injection-annular`}
          component={ChemicalInjectionAnnularPage}
        />
        <Route path={`${path}/shrouds`} component={ShroudsPage} />

        <Route path={`${path}/sand-safety-shield`} component={SandSafetyShieldPage} />
        <Route path={`${path}/centralizers`} component={CentralizersPage} />
      </div>
    )
  }
}
