import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Jump from 'react-reveal/Jump'

export const Button = props => {
  const { text = 'Button', onClick = () => {}, icon = false, addClass = '' } = props

  return (
    <Jump>
      <button className={`button${addClass && ' ' + addClass}`} onClick={onClick}>
        <div className="row">
          <div className="col-1">
            <FontAwesomeIcon icon={icon} />
          </div>
          <div className="col-4 center">
            <p>{text}</p>
          </div>
        </div>
      </button>
    </Jump>
  )
}

const bucket = () => (
  <Jump>
    <div class="contact-link">
      <a className="cta-bucket">
        <div className="cta-bucket-calibrate">
          <FontAwesomeIcon icon={faCommentDots} className="nav-icon" />
          <p>Get a Quote in 24 Hours - Contact Us</p>
        </div>
      </a>
    </div>
  </Jump>
)
